import Pusher from "pusher-js"

const realtime = (uid) => {
  let pusher = new Pusher(process.env.realtimeKey, {
    cluster: process.env.realtimeCluster,
    useTLS: true,
  })

  let channel = pusher.subscribe(
    `contentstack-freetrial-${process.env.api_key}-${process.env.environment}-entry-${uid}`
  )
  channel.bind(`publish`, e => location.reload())
  pusher.connection.bind("connected", () => console.log("connected"))
}

export default realtime
